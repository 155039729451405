import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const requAPI = {
  getRequs: topic.requTopic + '/get_requs',
  deleteRequByIds: topic.requTopic + '/delete_requ_by_ids',
  addRequ: topic.requTopic + '/add_requ',
  editRequ: topic.requTopic + '/edit_requ',
  getRequById: topic.requTopic + '/get_requ_by_id',
  getRequSmplByRequNo: topic.requTopic + '/get_requ_smpl_by_requ_no',
  requPush9002List: topic.requTopic + '/get_push_requs_list',
  requPush9002NList: topic.requTopic + '/get_push_requsN_list',
  countPushRequ: topic.requTopic + '/count_push_requ',
  finishRequPush: topic.requTopic + '/finish_requ_push',
  getRequSmpls: topic.requTopic + '/get_requ_smpls',
  getmouldSmpls: params => getNoCatch('/api/mould/getMoulds', params),
  export_requ_part: params => getNoCatch(`${topic.requTopic}/export_requ_part`, params),
  copyAndEdit: params => getNoCatch(`${topic.requTopic}/copy_requ_by_id`, params),
  getRequestpush: params => getNoCatch(`${topic.requTopic}/getRequestpush`, params),
  deleteRequestpush: params => postNoCatch(`${topic.requTopic}/deleteRequestpush`, params),
  editBusinessBack: params => postNoCatch(`${topic.requTopic}/editBusinessBack`, params),
  editSampleStatus: params => postNoCatch(`${topic.requTopic}/editSampleStatus`, params),
  editSampleDocu: params => postNoCatch(`${topic.requTopic}/editSampleDocu`, params),
  editFequSample: params => postNoCatch(`${topic.requTopic}/editFequSample`, params),
  requPush9007Total: params => getNoCatch(`${topic.requTopic}/requPush9007Total`, params),
  editFequSample9007D: params => postNoCatch(`${topic.requTopic}/editFequSample9007D`, params),
  editFequSample9007Y: params => postNoCatch(`${topic.requTopic}/editFequSample9007Y`, params),
  editFequSample9007N: params => postNoCatch(`${topic.requTopic}/editFequSample9007N`, params),
  requInfomPush: params => getNoCatch(`${topic.requTopic}/requInfomPush`, params),
  get_requ_appr_inst: params => getNoCatch(`${topic.requTopic}/get_requ_appr_inst`, params),
  export_requ_appr_inst: params => getNoCatch(`${topic.requTopic}/export_requ_appr_inst`, params)
};
