<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span>推送提醒</span>
      </div>
      <div class="card_body">
        <el-row v-for="(item, index) in Object.values(pushListMap)" :key="index">
          <el-badge :value="item.perm_icon" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer" @click="goPermList(item.perm_url)">{{ item.perm_name }} </el-link>
          </el-badge>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { requAPI } from '@api/modules/requ';
import { fequAPI } from '@api/modules/fequ';
import bus from '@/components/common/bus';
import { inquiryAPI } from '@api/modules/inquiry';
import { sconAPI } from '@api/modules/scon';
import { pushAPI } from '@api/modules/push';

export default {
  name: 'boardPush',
  data() {
    return {
      pushList: [],
      pushListPermIds: [],
      pushListMap: {},
      userInfo: {}
    };
  },
  created() {
    this.pushList = this.$cookies.get('push');
    this.pushList.forEach(item => {
      this.pushListMap[item.perm_id] = item;
    });
    this.userInfo = this.$cookies.get('userInfo');
    this.initData();
  },
  mounted() {
    bus.$on('getPermInfo', msg => {
      this.initData();
    });
  },
  methods: {
    initData() {
      this.getPermInfo();
    },
    //获取perm信息
    getPermInfo() {
      this.getRequPush();
      this.getFequPush9003();
      this.getFequPush9004();
      this.getInquPush9005();
      this.get9006();
      this.get9007();
      this.get9008();
      this.get9009();
      this.get9010();
    },
    getRequPush() {
      let temp = this.pushListMap['9002'];
      if (!temp) return;
      getNoCatch(requAPI.countPushRequ, {}).then(({ data }) => {
        temp.perm_icon = data;
      });
    },
    getFequPush9003() {
      let temp = this.pushListMap['9003'];
      if (!temp) return;
      getNoCatch(fequAPI.fequPush9003Total, { stff_id: this.userInfo.stff_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    getFequPush9004() {
      let temp = this.pushListMap['9004'];
      if (!temp) return;
      getNoCatch(fequAPI.requPush9004Total, { stff_id: this.userInfo.stff_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    getInquPush9005() {
      let temp = this.pushListMap['9005'];
      if (!temp) return;
      getNoCatch(inquiryAPI.inquPush9005Total, { user_id: this.userInfo.user_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    get9006() {
      let temp = this.pushListMap['9006'];
      if (!temp) return;
      sconAPI.sconInvPush9006Total({ user_id: this.userInfo.user_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    get9007() {
      let temp = this.pushListMap['9007'];
      if (!temp) return;
      requAPI.requPush9007Total({ requ_taker: this.userInfo.stff_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    get9008() {
      let temp = this.pushListMap['9008'];
      if (!temp) return;
      fequAPI.getfequPush9008Total({ stff_id: this.userInfo.stff_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    get9009() {
      let temp = this.pushListMap['9009'];
      if (!temp) return;
      fequAPI.getfequPush9009Total({ stff_id: this.userInfo.stff_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    get9010() {
      let temp = this.pushListMap['9010'];
      if (!temp) return;
      pushAPI.get9010Count({ stff_id: this.userInfo.stff_id }).then(({ data }) => {
        temp.perm_icon = data.total;
      });
    },
    goPermList(val) {
      this.jump(val);
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath === '/dashboard') {
        this.initData();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper-box {
  padding-left: 12px !important;
}

.vd_item {
  // width: 130px;
  margin-top: 12px;
  margin-right: 40px;
}

@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}

@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}
</style>
