<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently"
          >被审批 <span :class="{ vg_rotate: rotateFlag }" class="el-icon-refresh" @click="loadingChange"></span><i> (最近20条)</i>
          <span style="color: red">如需获取最新数据请刷新</span>
        </span>
        <div class="vd_card_button">
          <el-button type="text" @click="goApprovalRecord()">审批记录</el-button>
          <el-button type="text" class="vd_card_button" @click="goAllAprMe()">查看全部</el-button>
        </div>
      </div>
      <div ref="div" :style="'height:' + currentInnerHeight + 'px'" style="overflow-y: auto">
        <div v-if="rightList.length <= 0" class="el-icon-warning-outline flexHV" style="height: 100%; width: 100%; font-size: 24px; color: #ccc">
          暂无被审批
        </div>
        <div v-else v-for="(item, index) in rightList" :key="index + 'index'" class="apprDivClass">
          <div class="flexV">
            <span style="width: 100px; color: #606266">审批时间:</span>
            <span>{{ helper.toTime(item.update_time) }}</span>
            <el-tag
              v-if="item.appr_inst_action === 3 && item.appr_inst_next_node <= 0"
              class="vd_kid"
              size="mini"
              style="margin-left: auto; margin-right: 5px"
              type="danger"
            >
              草拟(拒绝)
            </el-tag>
            <el-tag
              v-else-if="item.appr_inst_action === 3 && item.appr_inst_next_node > 0"
              class="vd_kid"
              size="mini"
              style="margin-left: auto; margin-right: 5px"
              type="danger"
            >
              在批(拒绝)
            </el-tag>
            <el-tag
              v-else
              style="margin-left: auto; margin-right: 5px"
              :type="helper.getInstNextNode(item.appr_inst_next_node).type"
              size="mini"
              class="vd_kid"
            >
              {{ helper.getInstNextNode(item.appr_inst_next_node).name }}
            </el-tag>
          </div>
          <div class="flexV">
            <span style="width: 100px; color: #606266">发起人说明:</span>
            <el-link type="primary" @click="goAppr(item.appr_inst_url)" :underline="false" class="vg_pointer">
              <span v-html="item.appr_inst_info"></span>
            </el-link>
          </div>
          <div class="flexV">
            <span style="width: 100px; color: #606266">原因:</span>
            <span>{{ item.appr_inst_remark || '暂无' }}</span>
          </div>
        </div>
        <!--        <el-card shadow="hover" v-for="(item, index) in rightList" :key="index" class="vg_mt_8" v-loading="loading">
          <div>
            <el-form size="mini" label-width="100px" :label-position="labelPosition">
              <el-row>
                <el-col>
                  <el-form-item label="审批时间：" prop="update_time" class="vd_father">
                    {{ helper.toTime(item.update_time) }}
                    <el-tag :type="helper.getInstNextNode(item.appr_inst_next_node).type" size="mini" class="vd_kid">{{
                      helper.getInstNextNode(item.appr_inst_next_node).name
                    }}</el-tag>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="发起人说明：" prop="appr_inst_info">
                    <el-link :underline="false" @click="goAppr(item.appr_inst_url)" type="primary" class="vg_pointer">
                      {{ item.appr_inst_info }}</el-link
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-card>-->
      </div>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { apprAPI } from '@/api/modules/appr';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'boardRight',
  data() {
    return {
      labelPosition: 'left',
      rightList: [],
      linkHead: 'http://localhost:8888',
      loading: true,
      rotateFlag: false,
      currentInnerHeight: 0
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    let { y } = this.$refs.div.getBoundingClientRect();
    this.currentInnerHeight = window.innerHeight - y - 40;
  },
  methods: {
    initData() {
      this.getRightList();
      setTimeout(() => {
        this.rotateFlag = false;
      }, 1100);
    },
    //获取被审批信息
    getRightList() {
      get(apprAPI.getApproveMe)
        .then(res => {
          if (res.data.code === 0) {
            setTimeout(() => {
              this.loading = false;
            }, 300);
            this.rightList = res.data.data;
            this.rightList.forEach(item => {
              let num = 40;
              if (item.appr_inst_info.indexOf('span') !== -1) num += 30;
              if (item.appr_inst_info.length > num) item.appr_inst_info = item.appr_inst_info.substring(0, num) + '...';
            });
          } else if (res.data.code === 3) {
            console.log('未登录');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    goAppr(val) {
      let newArr = val.split('?');
      let newArr2 = newArr[1].split('&');
      let prop1 = newArr2[0].split('=');
      let prop2 = newArr2[1].split('=');
      let formObj = {};
      formObj[prop1[0]] = prop1[1];
      formObj[prop2[0]] = prop2[1];

      this.jump(newArr[0], { key: UrlEncode.encode(JSON.stringify(formObj)) });
    },
    goAllAprMe() {
      this.jump('/beApproved_all');
    },
    goApprovalRecord() {
      this.jump('/toApproval_record');
    },
    // 刷新被审批的人数
    loadingChange() {
      this.loading = true;
      this.rotateFlag = true;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.apprDivClass {
  border: 1px solid #ebeef5;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 4px;
}
.wrapper-box ::v-deep .el-card__header {
  padding: 8px 20px;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
  }
}
.vd_father {
  position: relative;
  .vd_kid {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.card_body {
  padding-right: 10px;
  overflow: auto;
  position: relative;
}
@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}
@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}
.Recently {
  i {
    font-size: 10px;
    font-style: inherit;
    color: #ccc;
  }
  span {
    margin: 0 10px;
    cursor: pointer;
    &:hover {
      color: blue;
    }
  }
}

// 无审批
.notVal {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
}
</style>
