<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently flexV"
          >需审批
          <span :class="{ vg_rotate: rotateFlag }" class="el-icon-refresh" @click="loadingChange"></span>
          <i> (最近20条)</i><span style="color: red">如需获取最新数据请刷新</span>
        </span>
        <el-button type="text" class="vd_card_button" @click="goAllApr()">查看全部</el-button>
      </div>
      <div ref="div" :style="'height:' + currentInnerHeight + 'px'" style="overflow-y: auto">
        <div v-if="apprNeedList.length <= 0" class="el-icon-warning-outline flexHV" style="height: 100%; width: 100%; font-size: 24px; color: #ccc">
          暂无需审批
        </div>
        <div v-else v-for="(item, index) in apprNeedList" :key="index + 'index'" class="apprDivClass">
          <div class="flexV">
            <span style="width: 100px; color: #606266">审批时间:</span>
            <span>{{ helper.toTime(item.create_time) }}</span>
          </div>
          <div class="flexV">
            <span style="width: 100px; color: #606266">发起人说明:</span>
            <el-link type="primary" @click="goAppr(item.appr_inst_url)" :underline="false" class="vg_pointer">
              {{ item.appr_inst_info }}
            </el-link>
          </div>
          <div class="flexV">
            <span style="width: 100px; color: #606266">备注:</span>
            <span>{{ item.appr_inst_remark || '暂无' }}</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { apprAPI } from '@api/modules/appr';
import { userAPI } from '@api/modules/user';
import bus from '@/components/common/bus';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'boardLeft',
  data() {
    return {
      labelPosition: 'left',
      apprNeedList: [],
      loading: true,
      rotateFlag: false,
      currentInnerHeight: 0
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    bus.$on('refreshTheApprovalListOnTheHomepage', () => {
      this.initData();
    });
    let { y } = this.$refs.div.getBoundingClientRect();
    this.currentInnerHeight = window.innerHeight - y - 40;
  },
  methods: {
    initData() {
      this.getApprove();
      setTimeout(() => {
        this.rotateFlag = false;
      }, 1100);
    },
    // 获取待审批记录
    getApprove() {
      get(apprAPI.getIApprove)
        .then(res => {
          if (res.data.code === 0) {
            setTimeout(() => {
              this.loading = false;
            }, 300);
            this.apprNeedList = res.data.data;
            this.apprNeedList.forEach(item => {
              if (item.appr_inst_info.length > 40) item.appr_inst_info = item.appr_inst_info.substring(0, 40) + '...';
            });
          } else if (res.data.code === 3) {
            this.$router.push('/login');
            this.deleteCookie();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    goAppr(val) {
      let newArr = val.split('?');
      let newArr2 = newArr[1].split('&');
      let prop1 = newArr2[0].split('=');
      let prop2 = newArr2[1].split('=');
      let formObj = {};
      formObj[prop1[0]] = prop1[1];
      formObj[prop2[0]] = prop2[1];

      this.jump(newArr[0], { key: UrlEncode.encode(JSON.stringify(formObj)) });
    },
    goAllApr() {
      this.jump('/toApproved_all');
    },
    // 刷新被审批的人数
    loadingChange() {
      this.loading = true;
      this.rotateFlag = true;
      this.initData();
    },
    // 删除cookie
    deleteCookie() {
      get(userAPI.userLogout).then(res => {
        if (res.data.code === 0) {
          this.$cookies.remove('userInfo');
          this.$cookies.remove('push');
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.apprDivClass {
  border: 1px solid #ebeef5;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 4px;
}
.wrapper-box ::v-deep .el-card__header {
  padding: 8px 20px;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}
@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}
.card_body {
  padding-right: 10px;
  overflow: auto;
  position: relative;
}

// 无审批
.notVal {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
}

.Recently {
  i {
    font-size: 10px;
    font-style: inherit;
    color: #ccc;
  }
  span {
    margin: 0 10px;
    cursor: pointer;
    &:hover {
      color: blue;
    }
  }
}
</style>
