<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :md="10">
        <board-left></board-left>
      </el-col>
      <el-col :md="10">
        <board-right></board-right>
      </el-col>
      <el-col :md="4">
        <board-push></board-push>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="24" class="vg_mb_8 vg_mt_8">
        <el-col v-if="panel1" :md="12">
          <el-select v-model="sconLineDept" disabled placeholder="请选择部门" size="small" style="width: 150px">
            <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-date-picker
            v-model="sconLineDateRange"
            :picker-options="pickerOptions"
            class="vg_ml_16 topDateRangeSearch"
            end-placeholder="结束月份"
            range-separator="至"
            size="small"
            start-placeholder="开始月份"
            type="monthrange"
            value-format="timestamp"
            @change="initSconLineAndPieChart"
          >
          </el-date-picker>
        </el-col>
        <el-col v-if="panel2" :md="12">
          <el-select
            v-model="sconPieDept"
            clearable
            collapse-tags
            filterable
            multiple
            placeholder="请选择部门"
            size="small"
            style="width: 180px"
            @visible-change="val => (!val ? initSconLineAndPieChart() : () => {})"
          >
            <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <!--<el-button class="vg_ml_8" size="mini" type="primary" @click="initSconLineAndPieChart">查询</el-button>-->
        </el-col>
      </el-col>
      <el-col v-loading="loading1" :gutter="24">
        <el-col v-if="panel1" :md="12" class="vg_mb_8">
          <el-card style="width: 100%">
            <div ref="sconLineChart" style="width: calc(100%); height: 400px"></div>
          </el-card>
        </el-col>
        <el-col v-for="(item, index) in sconPieChartLength" v-if="panel2" :key="index" :md="12" class="vg_mb_8">
          <el-card style="width: 100%">
            <div :ref="`sconPieChart${index}`" style="width: calc(100%); height: 400px"></div>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="24" class="vg_mb_8 vg_mt_8">
        <el-col v-if="panel3" :md="12">
          <el-select v-model="podrLineDept" disabled placeholder="请选择部门" size="small" style="width: 150px">
            <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-date-picker
            v-model="podrLineDateRange"
            :picker-options="pickerOptions"
            class="vg_ml_16 topDateRangeSearch"
            end-placeholder="结束月份"
            range-separator="至"
            size="small"
            start-placeholder="开始月份"
            type="monthrange"
            value-format="timestamp"
            @change="initPodrLineAndPieChart"
          >
          </el-date-picker>
        </el-col>
        <el-col v-if="panel4" :md="12">
          <el-select
            v-model="podrPieDept"
            clearable
            collapse-tags
            filterable
            multiple
            placeholder="请选择部门"
            size="small"
            style="width: 180px"
            @visible-change="val => (!val ? initPodrLineAndPieChart() : () => {})"
          >
            <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <!--<el-button class="vg_ml_8" size="mini" type="primary" @click="initPodrLineAndPieChart">查询</el-button>-->
        </el-col>
      </el-col>
      <el-col v-loading="loading2" :gutter="24">
        <el-col v-if="panel3" :md="12" class="vg_mb_8">
          <el-card style="width: 100%">
            <div ref="podrLineChart" style="width: calc(100%); height: 400px"></div>
          </el-card>
        </el-col>
        <el-col v-for="(item, index) in podrPieChartLength" v-if="panel4" :key="index" :md="12" class="vg_mb_8">
          <el-card style="width: 100%">
            <div :ref="`podrPieChart${index}`" style="width: calc(100%); height: 400px"></div>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
    <el-row v-if="panel5" :gutter="20">
      <el-col :md="24" class="vg_mb_8 vg_mt_8">
        <el-select v-model="modrLineDept" disabled placeholder="请选择部门" size="small" style="width: 150px">
          <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-date-picker
          v-model="modrLineDateRange"
          :picker-options="pickerOptions"
          class="vg_ml_16 topDateRangeSearch"
          end-placeholder="结束月份"
          range-separator="至"
          size="small"
          start-placeholder="开始月份"
          type="monthrange"
          value-format="timestamp"
          @change="initModrLineChart"
        >
        </el-date-picker>
        <!--<el-button class="vg_ml_8" size="mini" type="primary" @click="initModrLineChart">查询</el-button>-->
      </el-col>
      <el-col v-loading="loading3" :md="24" class="vg_mb_8 vg_mt_8">
        <el-card style="width: 100%">
          <div ref="modrLineChart" style="width: calc(100%); height: 400px"></div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="24" class="vg_mb_8 vg_mt_8">
        <el-col v-if="panel6" :md="12">
          <el-select v-model="tranLineDept" disabled placeholder="请选择部门" size="small" style="width: 150px">
            <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-date-picker
            v-model="tranLineDateRange"
            :picker-options="pickerOptions"
            class="vg_ml_16 topDateRangeSearch"
            end-placeholder="结束月份"
            range-separator="至"
            size="small"
            start-placeholder="开始月份"
            type="monthrange"
            value-format="timestamp"
            @change="initTranLineAndPieChart"
          >
          </el-date-picker>
        </el-col>
        <el-col v-if="panel7" :md="12" style="padding-left: 12px">
          <el-select
            v-model="tranPieDept"
            clearable
            collapse-tags
            filterable
            multiple
            placeholder="请选择部门"
            size="small"
            style="width: 150px"
            @visible-change="val => (!val ? initTranLineAndPieChart() : () => {})"
          >
            <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <!--<el-button class="vg_ml_8" size="mini" type="primary" @click="initTranLineAndPieChart">查询</el-button>-->
        </el-col>
      </el-col>
      <el-col v-loading="loading4" :gutter="24">
        <el-col v-if="panel6" :md="12" class="vg_mb_8">
          <el-card style="width: 100%">
            <div ref="tranLineChart" style="width: calc(100%); height: 400px"></div>
          </el-card>
        </el-col>
        <el-col v-if="panel7" :md="12" class="vg_mb_8">
          <el-card style="width: 100%">
            <div ref="tranPieChart" style="width: calc(100%); height: 400px"></div>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import boardLeft from '@/views/component/boardCommon/boardLeft';
import boardRight from '@/views/component/boardCommon/boardRight';
import boardPush from '@/views/component/boardCommon/boardPush';
import * as echarts from 'echarts';
import { getDept, setTime } from '@api/public';
import { openAPI } from '@api/modules/open';
import {
  modrLineChartOptions,
  podrLineChartOptions,
  podrPieChartOptions,
  sconLineChartOptions,
  sconPieChartOptions,
  tranLineChartOptions,
  tranPieChartOptions
} from '@/views/echartsOptions';
import { cloneDeep } from 'lodash';

export default {
  name: 'Home',
  components: {
    boardPush,
    boardLeft,
    boardRight
  },
  data() {
    return {
      deptList: [],
      sconLineDept: '',
      sconPieDept: '',
      sconLineDateRange: [],
      podrLineDept: '',
      podrPieDept: '',
      podrLineDateRange: [],
      modrLineDept: '',
      modrLineDateRange: [],
      tranLineDept: '',
      tranPieDept: '',
      tranLineDateRange: [],
      minDate: null,
      maxDate: null,
      pickerOptions: {
        disabledDate: time => {
          if (this.minDate !== null && this.maxDate === null) {
            const selectedYear = this.minDate.getFullYear();
            const lastDayOfYear = new Date(selectedYear, 11, 31);
            return time < this.minDate || time > lastDayOfYear;
          }
          return false;
        },
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate;
          this.maxDate = maxDate;
        }
      },
      recentThreeMonths: [],
      sconPieChartLength: null,
      podrPieChartLength: null,
      panel1: false,
      panel2: false,
      panel3: false,
      panel4: false,
      panel5: false,
      panel6: false,
      panel7: false,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false
    };
  },
  created() {
    this.initData();
  },
  async mounted() {
    await this.permissionConfiguration();
    this.recentThreeMonths = this.getRecentThreeMonths();
    this.initSconLineAndPieChart();
    this.initPodrLineAndPieChart();
    this.initModrLineChart();
    this.initTranLineAndPieChart();
  },
  methods: {
    async initData() {
      this.deptList = await getDept();
    },
    async permissionConfiguration() {
      let { dept_id: currentUserDeptId, stff_id } = this.$cookies.get('userInfo');
      let deptIds = await getDept(['业务一部', '业务二部', '业务三部', '业务四部', '业务五部', '电商部'], 'managerIds');
      let deptIds2 = await getDept(['凡爱内贸部'], 'managerIds');
      let caigouDeptIds = await getDept(['采购一部', '采购二部'], 'managerIds');
      let fuliaoDeptIds = await getDept(['辅料部'], 'managerIds');
      let zjlDeptIds = await getDept(['总经理室'], 'ids');
      let isAdmin = stff_id === 1;
      if (deptIds.concat(deptIds2).includes(stff_id) || isAdmin) {
        this.panel1 = true;
      }
      if (zjlDeptIds.includes(currentUserDeptId) || isAdmin) {
        this.panel2 = true;
        this.panel4 = true;
        this.panel7 = true;
      }
      if (caigouDeptIds.concat(fuliaoDeptIds).includes(stff_id) || isAdmin) {
        this.panel3 = true;
      }
      if (deptIds.concat(deptIds2).includes(stff_id) || zjlDeptIds.includes(currentUserDeptId) || isAdmin) {
        this.panel5 = true;
      }
      if (deptIds.includes(stff_id) || isAdmin) {
        this.panel6 = true;
      }
      this.sconLineDept = currentUserDeptId;
      this.podrLineDept = currentUserDeptId;
      this.modrLineDept = currentUserDeptId;
      this.tranLineDept = currentUserDeptId;
    },
    initSconLineAndPieChart() {
      this.loading1 = true;
      let requestForm = {};
      setTime(requestForm, 'startTime', 'endTime', this.sconLineDateRange || [], false);
      requestForm.dept_ids = this.sconPieDept.toString();
      openAPI.getSconDataBoard(requestForm).then(({ data }) => {
        if (this.$refs.sconLineChart) {
          const lineChartInstance = echarts.init(this.$refs.sconLineChart);
          lineChartInstance.clear();
          const sconLine = cloneDeep(sconLineChartOptions);
          sconLine.xAxis.data = this.sconLineDateRange.length > 0 ? this.getTimePeriod(this.sconLineDateRange) : this.recentThreeMonths;
          sconLine.series = data.deptScon;
          lineChartInstance.setOption(sconLine);
        }
        this.sconPieChartLength = data.deptScon1.length;
        setTimeout(() => {
          data.deptScon1.forEach((item, index) => {
            if (this.$refs[`sconPieChart${index}`] && this.$refs[`sconPieChart${index}`][0]) {
              const pieChartInstance = echarts.init(this.$refs[`sconPieChart${index}`][0]);
              pieChartInstance.clear();
              const sconPie = cloneDeep(sconPieChartOptions);
              sconPie.series[0].name = item.name;
              sconPie.series[0].data = item.data;
              sconPie.title.text = item.name;
              pieChartInstance.setOption(sconPie);
            }
          });
        }, 1000);
        setTimeout(() => (this.loading1 = false));
      });
    },
    initPodrLineAndPieChart() {
      this.loading2 = true;
      let requestForm = {};
      setTime(requestForm, 'startTime', 'endTime', this.podrLineDateRange || [], false);
      requestForm.dept_ids = this.podrPieDept.toString();
      openAPI.getPodrDataBoard(requestForm).then(({ data }) => {
        if (this.$refs.podrLineChart) {
          const lineChartInstance = echarts.init(this.$refs.podrLineChart);
          lineChartInstance.clear();
          const podrLine = cloneDeep(podrLineChartOptions);
          podrLine.xAxis.data = this.podrLineDateRange.length > 0 ? this.getTimePeriod(this.podrLineDateRange) : this.recentThreeMonths;
          podrLine.series = data.deptPodr;
          lineChartInstance.setOption(podrLine);
        }
        this.podrPieChartLength = data.deptPodr1.length;
        setTimeout(() => {
          data.deptPodr1.forEach((item, index) => {
            if (this.$refs[`podrPieChart${index}`] && this.$refs[`podrPieChart${index}`][0]) {
              const pieChartInstance = echarts.init(this.$refs[`podrPieChart${index}`][0]);
              pieChartInstance.clear();
              const podrPie = cloneDeep(podrPieChartOptions);
              podrPie.series[0].name = item.name;
              podrPie.series[0].data = item.data;
              podrPie.title.text = item.name;
              pieChartInstance.setOption(podrPie);
            }
          });
        }, 1000);
        setTimeout(() => (this.loading2 = false));
      });
    },
    initModrLineChart() {
      this.loading3 = true;
      let requestForm = {};
      setTime(requestForm, 'startTime', 'endTime', this.modrLineDateRange || [], false);
      openAPI.getModrDataBoard(requestForm).then(({ data }) => {
        if (this.$refs.modrLineChart) {
          const lineChartInstance = echarts.init(this.$refs.modrLineChart);
          lineChartInstance.clear();
          const modrLine = cloneDeep(modrLineChartOptions);
          modrLine.xAxis.data = this.modrLineDateRange.length > 0 ? this.getTimePeriod(this.modrLineDateRange) : this.recentThreeMonths;
          modrLine.series = data.deptModr;
          lineChartInstance.setOption(modrLine);
        }
        setTimeout(() => (this.loading3 = false));
      });
    },
    initTranLineAndPieChart() {
      this.loading4 = true;
      let requestForm = {};
      setTime(requestForm, 'startTime', 'endTime', this.tranLineDateRange || [], false);
      requestForm.dept_ids = this.tranPieDept.toString();
      openAPI.getTranDataBoard(requestForm).then(({ data }) => {
        if (this.$refs.tranLineChart) {
          const lineChartInstance = echarts.init(this.$refs.tranLineChart);
          lineChartInstance.clear();
          const tranLine = cloneDeep(tranLineChartOptions);
          tranLine.series[0].data = data.deptTran[0].datay;
          tranLine.xAxis.data = data.deptTran[0].datax;
          lineChartInstance.setOption(tranLine);
        }
        if (this.$refs.tranPieChart) {
          const pieChartInstance = echarts.init(this.$refs.tranPieChart);
          pieChartInstance.clear();
          const tranPie = cloneDeep(tranPieChartOptions);
          tranPie.series[0].data = data.deptTran1;
          pieChartInstance.setOption(tranPie);
        }
        setTimeout(() => (this.loading4 = false));
      });
    },
    getRecentThreeMonths() {
      const months = [];
      const now = new Date();
      // 循环三次，从当前月份开始，回溯到前两个月
      for (let i = 0; i <= 2; i++) {
        // 获取月份，注意JavaScript中的月份是从0开始的，所以需要加1
        const month = now.getMonth() - i + 1;
        // 获取年份，如果month变负数了，需要调整年份并修正month
        const year = month > 0 ? now.getFullYear() : now.getFullYear() - 1;
        // 修复可能出现的负数月份，使之转换为正确的月份（例如，上一年的12月）
        const correctedMonth = month > 0 ? month : 12 + month;

        const monthStr = `${correctedMonth}月`;
        months.push(monthStr);
      }
      return months.reverse();
    },
    getTimePeriod(monthTimestamps) {
      // 确保输入是按升序排列的
      monthTimestamps.sort((a, b) => a - b);

      // 将时间戳转换为Date对象，获取起始月份
      const startDate = new Date(monthTimestamps[0]);
      const startMonth = startDate.getMonth(); // 注意：getMonth() 返回的月份是从0开始的，即0代表1月

      // 获取该年的最后一个月（12月）
      const endDateOfYear = new Date(startDate.getFullYear(), 11, 31);

      // 初始化月份数组
      const months = [];

      // 从起始月份遍历到年底的每个月
      for (let month = startMonth; month <= endDateOfYear.getMonth(); month++) {
        // 使用getMonth() + 1是因为月份是从0开始的
        months.push(`${month + 1}月`);
      }

      return months;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_card_button {
  padding: 2px 0px;
}

.el-row {
  :nth-child(3) {
    //padding-left: 0px !important;
  }
}
</style>
